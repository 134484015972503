<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('service.folders.overview')" class="service service_question_folders">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 text-left">
              {{$t('service.Folders')}}
            </CCol>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
              <div v-if="checkPermission('service.folders.add')" class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="m-0 link" color="primary" @click="openSidebarRight('service_question_folder_details', { service_question_folder_id_external: null })">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{$t('service.Add_folder')}}</span>
                </CButton>
              </div>
            </CCol>             
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow v-if="foldersLoading === true">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0': folders.length > 0}">
              <div v-if="folders.length > 0">
                <b-table ref="foldersTable"
                         class="data_table"
                         :data="folders"
                         :striped="true"
                         :hoverable="true"
                         :mobile-cards="true"
                         :current-page.sync="currentPage"
                         :paginated="isPaginated"
                         :per-page="perPage"
                         :pagination-simple="isPaginationSimple"
                         :pagination-position="paginationPosition"
                         :default-sort="[sortField, sortOrder]"
                         :default-sort-direction="defaultSortDirection"
                         :sort-icon="sortIcon"
                         :sort-icon-size="sortIconSize">
                  
                  <template slot-scope="props">
                    <b-table-column field="folder_name" :label="$t('service.Folder_name')" :searchable="searchEnabled" :sortable="true" width="15%">
                      <span>{{props.row.folder_name}}</span>
                    </b-table-column>
                    <b-table-column field="folder_description" :label="$t('common.Description')" :searchable="searchEnabled" width="35%">
                      <span>{{props.row.folder_description}}</span>
                    </b-table-column>
                    <b-table-column field="targets" :label="$t('service.Folder_targets')">
                      <!-- <folderTargetsPopover :serviceQuestionFolderIdExternal="props.row.service_question_folder_id_external" popoverPlacement="left"/> -->
                      <div class="item_tags">
                        <b-tag v-for="target in props.row.targets" 
                              v-bind:key="target.service_question_folder_target_id_external" 
                              class="mt-1 info">
                          <span>{{$t('service.Target_' + target.service_question_folder_target_type_tag)}} <b>{{target.target_value}}</b> {{$t('common.hours')}}</span>
                        </b-tag>
                      </div>
                    </b-table-column>                    
                    <b-table-column field="target_groups" label="" class="text-right" width="5%">
                      <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                          targetGroupsType="service questions folder"
                                          :targetGroupsIdExternal="props.row.service_question_folder_id_external"
                                          popoverPlacement="left"/>
                    </b-table-column>
                    <b-table-column field="action" width="10%" :visible="checkPermission('service.folders.edit')">
                      <div class="d-flex justify-content-lg-end">
                        <CButton color="primary"
                                 class="m-0"
                                 v-bind:class="{'disabled' : props.row.general_folder}"
                                 v-c-tooltip="props.row.general_folder ? {placement: 'left', content: $t('service.Folder_managed_by_owner')} : ''"
                                 @click="!props.row.general_folder ? openSidebarRight('service_question_folder_details', { service_question_folder_id_external: props.row.service_question_folder_id_external }) : null">
                          <i class="fas fa-pen"/>
                        </CButton>
                      </div>
                    </b-table-column>                                        
                  </template>              
                </b-table>
              </div>
              <div v-else class="text-center">                
                <span>{{$t('service.No_folders_available')}}</span>
              </div>                
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import targetGroupPopover from '@/components/common/targetGroupPopover.vue';

import folderTargetsPopover from '@/components/service/folderTargetsPopover.vue';

export default {
  name: 'Folders',
  components: {
    QuickEdit,
    Multiselect,
    loadingSpinner,
    noPermission,
    userProfilePopover,
    targetGroupPopover,
    folderTargetsPopover
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,      
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      folders: [],
      foldersLoading: true,
      foldersLoaded: false,
      sortField: 'folder_name',
      sortOrder: 'asc',
      defaultSortDirection: 'asc',
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: false
    }
  },
  methods: {
    getFolders() {
      // Start the loader
      if(this.foldersLoaded === false) this.foldersLoading = true;
      // Get the folders
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/questions/folders/overview')
      .then(res => {
        this.folders = res.data.data;
        // Stop the loader
        this.foldersLoading = false;
        // Update the foldersLoaded value
        this.foldersLoaded = true; 
      })
      .catch(err => {
        console.error(err); 
      });
    },       
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }          
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
        
    this.getPlatformPermissions();
    this.getFolders();

    this.$bus.$on('update_service_question_folders', () => {
      this.getFolders();
    });  
  },
  beforeDestroy() {
    this.$bus.$off('update_service_question_folders');
  }
}
</script>