<template>
  <v-popover offset="10"
             :placement="popoverPlacement"
             trigger="hover"
             popoverClass="dashboard_popover"
             popoverWrapperClass="dashboard_popover_wrapper"
             popoverInnerClass="dashboard_popover_content"
             boundariesElement=".popover_trigger"
             @show="getFolderDetails()">

    <div class="popover_trigger">      
      <i class="icon fa-solid fa-stopwatch"></i>
    </div>

    <template slot="popover">
      <CCard  class="mb-0">
        <CCardBody>
          <CRow v-if="folderTargetsLoading === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="item_tags">
                <b-tag v-for="target in folderTargets" 
                       v-bind:key="target.service_question_folder_target_id_external" 
                       class="mt-1 info">
                  <span>{{$t('service.Target_' + target.service_question_folder_target_type_tag)}} <b>{{target.target_value}}</b> {{$t('common.hours')}}</span>
                </b-tag>
              </div>
            </CCol>
          </CRow>                    
        </CCardBody>
      </CCard>
    </template>
  </v-popover>
</template>

<script>
import axios from 'axios';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'folderTargetsPopover',
  props: ['serviceQuestionFolderIdExternal', 'popoverPlacement'],
  components: {
    loadingSpinner
  },
  data() {
    return {
      folderTargets: [],
      folderTargetsLoading: false,
      folderTargetsLoaded: false
    }
  },
  methods: {
    getFolderDetails() {
      if(this.folderTargetsLoaded === false) {
        // Show the loader
        this.folderTargetsLoading = true;
        // Get the target groups
        axios.get(process.env.VUE_APP_API_URL + '/v1/service/questions/folder/' + this.serviceQuestionFolderIdExternal)
        .then(res => {
          this.folderTargets = res.data.data.targets;          
          // Hide the loader and update the folderTargetsLoaded value after 250ms
          setTimeout(function() {
            this.folderTargetsLoading = false;
            this.folderTargetsLoaded = true;
          }.bind(this), 250);           
        })
        .catch(err => {
          console.error(err); 
        });
      }
    }
  }    
}
</script>